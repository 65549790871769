import type { ValidationContext } from '@blg/api-definition';
import { type Writable, writable } from 'svelte/store';

interface ValidationContextStore extends Writable<ValidationContext> {
	setValidationContext(validationContext: ValidationContext): void;
}

function createValidationContext(): ValidationContextStore {
	const { subscribe, set, update } = writable<ValidationContext>();

	return {
		subscribe,
		set,
		update,
		setValidationContext: (validationContext: ValidationContext) => set(validationContext)
	};
}

export const validationContextStore = createValidationContext();
