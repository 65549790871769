import { type Writable, writable } from 'svelte/store';
import type { SiteConfig } from '$lib/utils/siteConfig';

interface SiteConfigStore extends Writable<SiteConfig> {
	setSiteConfig: (siteConfig: SiteConfig) => void;
}

function createSiteConfigStore(): SiteConfigStore {
	const { subscribe, set, update } = writable<SiteConfig>();

	return {
		subscribe,
		set,
		update,
		setSiteConfig: (siteConfig) => siteConfigStore.set(siteConfig)
	};
}

export const siteConfigStore = createSiteConfigStore();
