export type RouteDefinition = {
	name: string;
	pattern: RegExp;
	pathname: string;
	public: boolean;
	accessible?: boolean;
	routesToHide?: string[];
	fullScreen?: boolean;
};

export const routesDefinition: RouteDefinition[] = [
	{
		name: 'dashboard',
		pattern: /^\/(dashboard)?$/,
		pathname: '/',
		public: false
	},
	{
		name: 'account',
		pattern: /^\/(account)?$/,
		pathname: '/account',
		public: false
	},
	{
		name: 'login',
		pattern: /^\/login/,
		pathname: '/login',
		public: true
	},
	{
		name: 'register',
		pattern: /^\/register/,
		pathname: '/register',
		public: true
	},
	{
		name: 'rentalFleet',
		pattern: /^\/rental_fleet\/(?!\/\d+)$/,
		pathname: '/rental_fleet',
		public: false
	},
	{
		name: 'contract',
		pattern: /^\/contract\/(?!\/\d+)$/,
		pathname: '/contract/',
		public: false
	},
	{
		name: 'sale',
		pattern: /^\/sale\/(?!\/\d+)$/,
		pathname: '/sale/',
		public: false
	},
	{
		name: 'service',
		pattern: /^\/service\/(?!\/\d+)$/,
		pathname: '/service/',
		public: false
	},
	// --  Listings
	{
		name: 'invoice',
		pattern: /^\/invoice\/(?!\/\d+)$/,
		pathname: '/invoice/',
		public: false
	},

	{
		name: 'fleet',
		pattern: /^\/fleet\/(?!\/\d+)$/,
		pathname: '/fleet',
		public: false
	},
	{
		name: 'message',
		pattern: /^\/message\/(?!\/\d+)$/,
		pathname: '/message',
		public: false
	},
	{
		name: 'alert',
		pattern: /^\/alert\/(?!\/\d+)$/,
		pathname: '/alert',
		public: false
	},
	{
		name: 'collaborator',
		pattern: /^\/collaborator\/(?!\/\d+)$/,
		pathname: '/collaborator',
		public: false
	},

	// -- Views

	{
		name: 'contract_view',
		pattern: /^\/contract\/(\d+)?/,
		pathname: '/contract/[itemId]',
		public: false,
		fullScreen: true
	},

	{
		name: 'message_view',
		pattern: /^\/message\/(\d+)?/,
		pathname: '/message/[itemId]',
		public: false,
		fullScreen: true
	},

	{
		name: 'sale_view',
		pattern: /^\/sale\/[a-z_]+\/\d+\/$/,
		pathname: '/sale/[itemId]',
		public: false,
		fullScreen: true
	},

	{
		name: 'invoice_view',
		pattern: /^\/invoice\/(\d+)?/,
		pathname: '/invoice/[itemId]',
		public: false,
		fullScreen: true
	},

	{
		name: 'equipment_view',
		pattern: /^\/equipment\/(\d+)?/,
		pathname: '/equipment/[itemId]',
		public: true,
		fullScreen: true
	},
	{
		name: 'contract_validation',
		pattern: /^\/contract\/validation\/\d+\/$/,
		pathname: '/contract/validation/[itemId]',
		public: false
	}
];
